import VideoScrubber from "../VideoScrubber";
import Controls0 from "./ai-artist";
import Controls5 from "./style-transfer";
import Controls7 from "./hilbert";
import Controls8 from "./stylegan";
import Controls9 from "./maze-generation";
import Controls11 from "./sorting";
import Controls17 from "./life";
import Controls18 from "./pong4";
import Controls20 from "./graduation";
import Controls21 from "./wind";
import Controls27 from "./temple-photo-op";
import Controls28 from "./butterfly";
import Controls38 from "./ifs";
import Controls47 from "./photo-op";
const controls: Map<string, () => JSX.Element> = new Map([
  ["ai-artist", Controls0],
["metro", VideoScrubber],
["grendel", VideoScrubber],
["style-transfer", Controls5],
["hilbert", Controls7],
["stylegan", Controls8],
["maze-generation", Controls9],
["rams-horn", VideoScrubber],
["sorting", Controls11],
["cenote", VideoScrubber],
["witchscat", VideoScrubber],
["life", Controls17],
["pong4", Controls18],
["papa", VideoScrubber],
["graduation", Controls20],
["wind", Controls21],
["stowaway", VideoScrubber],
["sheriff", VideoScrubber],
["temple-photo-op", Controls27],
["butterfly", Controls28],
["owned", VideoScrubber],
["pajama-gladiator", VideoScrubber],
["death", VideoScrubber],
["ifs", Controls38],
["salt", VideoScrubber],
["taijitu", VideoScrubber],
["photo-op", Controls47],
["estefan", VideoScrubber]
]);
export default controls;
